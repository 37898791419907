body {
  font-family: "Noto Sans", sans-serif;
  font-size: 18px;
}
.navbar-expand .navbar-nav .nav-link {
  font-size: 0.9rem;
}

.intro-bg, .contact-bg, .coding-bg, .photo-bg {
  background-image: url("../public/assets/profile-2.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  padding: 25px 0;
}

.intro-bg:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to top right, #000, transparent);
  opacity: .6;
}

.intro-bg .overlay {
  position: absolute;
  bottom: 15%;
}

.intro-content, h1 {
  color: #fff;
  text-shadow: 1px 1px 10px #000;
}

a {
  color: #bada55;
  transition: 0.5s ease all;
}

a:hover {
  color: #97ba28;
}

.photo-page {
  background: #111;
  padding-bottom: 15px;
}
.photo-bg {
  background-image: url("../public/assets/lacrosse-example.jpg");
  margin-bottom:15px;
}
.photo-bg.second {
  background-image: url("../public/assets/soccer-example.jpg");
}
.photo-bg.third {
  background-image: url("../public/assets/real-estate-photos.jpg");
}
.photo-bg.fourth {
  background-image: url("../public/assets/backyard-example.jpg");
}

.photo-bg .overlay {
  height: 90%;
  display: flex;
  align-items: flex-end;
  color: #fff;
}

.coding-bg {
  background-image: url("../public/assets/coding-bg.jpg");
  height: 100%;
  margin-bottom:0;
}

.code-segment, .contact-bg .content, .photo-bg .content, .intro-content {
  background: rgba(0,0,0,.6);
  padding: 25px;
  box-shadow: 0 0 15px 15px rgb(0 0 0 / 60%);
  color: #fff;
}

.contact-bg {
  background-image: url("../public/assets/drone-1.jpg");
}

.gallery-bg {
  background-image: url("../public/assets/gallery-bg.jpg");
  background-size: cover;
  padding-bottom: 75px;
}

.gallery-bg h1 {
  margin: 50px 15px;
}

.gallery-bg p {
  margin: 20px 15px;
}

.gallery-bg a {
  color: #fff!important;
}

.gallery-bg a:hover {
  color: #333!important;
}

.content-left, .content-right {
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.contact-bg .content {
  margin-top: 50px;
}

@keyframes greyscale-fade-in {
  0% {
    -webkit-filter: grayscale(100%);
  }

  100% {
    -webkit-filter: grayscale(0%);
  }
}

.coding-bg, .photo-bg, .intro-bg, .contact-bg {
  animation: greyscale-fade-in 5s ease-in forwards;
}

@media screen and (min-width: 769px) {
  .intro-bg .overlay {
    left: 25%;
  }
  .navbar-expand .navbar-nav .nav-link {
    font-size: 1.25rem;
}
  .content-left {
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 25px 0;
  }
  .content-right {
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    padding: 25px 0;
  }
  .content-left div, .content-right div {
    padding: 15px;
  }
}